import art1 from "./artImgs/art1.png"
import art2 from "./artImgs/art2.png"
import art3 from "./artImgs/art3.png"
import art4 from "./artImgs/art4.png"
import art5 from "./artImgs/art5.png"
import art6 from "./artImgs/art6.jpg"

const articles = [
  {
    id: "1",
    link: "blog1-ramen",
    title: "Qu’est-ce qu’un ramen ?",
    image: art1,
    date: "16 Novembre 2019",
    intro:
      "Le ramen est sans aucun doute un incontournable de la gastronomie japonaise et a même donné naissance à une culture toute entière. Pourtant, avant de devenir ce plat iconique, le ramen a traversé les frontières jusqu’à trouver sa propre identité. Retour sur son histoire. ",
  },
  {
    id: "2",
    link: "blog2-manger-un-ramen",
    title: "L’art de manger un ramen",
    image: art2,
    date: "21 Novembre 2019",
    intro:
      "Si le ramen est un des plats les plus populaires au Japon, sa dégustation relève tout de même d’un art. Dans le film culte japonais Tampopo, un maître explique à son élève comment déguster un ramen. Et ce n’est pas sans raison, les japonais entretiennent une relation spéciale avec la nourriture et particulièrement les ramens. ",
  },
  {
    id: "3",
    link: "blog3-cantine-hara-kiri",
    title: "Pourquoi notre cantine s’appelle Hara-kiri ?",
    image: art3,
    date: "25 Novembre 2019",
    intro:
      "Hara-kiri, qui se traduit littéralement par « découpage de ventre », est un rituel japonais consistant à s’ouvrir l’abdomen avec un sabre. Les samouraïs ont lancé cette pratique, appelée également Suppuku au XIIe siècle au Japon. Elle a ensuite été bannie vers 1868. Il faut savoir que cette pratique suicidaire n’était utilisée qu’en dernier recours, et selon des circonstances précises : soit en cas de défaite, dans ce cas le samouraï optait pour le suicide afin de ne pas salir le nom de son seigneur ; soit lorsqu’un vassal critiquait ouvertement le Shogun (général), il préférait alors se suicider pour conserver son honneur et attirer son attention. ",
  },
  {
    id: "4",
    link: "blog4-reference-sakes",
    title: "Hara-Kiri, une référence en Saké",
    image: art4,
    date: "01 Décembre 2019",
    intro:
      "Chez Hara-Kiri, nous mettons le saké à l’honneur et nous vous proposons un large choix pour accompagner chaque mets et vous faire découvrir toutes les subtilités de cet alcool typiquement japonais. ",
  },
  {
    id: "5",
    link: "blog5-nos-cinq-ramens",
    title: "Quelles différences entre nos 5 ramens ?",
    image: art5,
    date: "04 Décembre 2019",
    intro:
      "Lors d’un voyage initiatique au Japon nous avons testé un nombre infini de ramens afin de découvrir tous les secrets des accords des différentes saveurs et choisir les meilleures recettes à rapporter en France. Nous avons donc sélectionné 4 recettes traditionnelles et emblématiques de ramens et y avons ajouté une dernière recette signature.  ",
  },
  {
    id: "6",
    link: "blog6-harakiri-biere",
    title: "Hara-Kiri brasse sa propre bière ",
    image: art6,
    date: "28 Mai 2020",
    intro:
      "Dans notre cantine, tout est fait maison et nous brassons même notre propre bière !",
  },
]

export default articles
