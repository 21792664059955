import React from "react"
import styled from "@emotion/styled"
import articles from "../articles-db"

const Blog = () => {
  const article = articles.find(art => art.id === "4")

  return (
    <ArticleSection>
      <ArticleContainer>
        <ArticleTextContainer>
          <ArticleTitle>{article.title}</ArticleTitle>
          <ArticleDate>Publié le {article.date}</ArticleDate>

          <ArticleImageContainer>
            <img
              src={article.image}
              alt={article.title}
              className="articleImage"
            ></img>
          </ArticleImageContainer>
          <ArticleIntro>{article.intro}</ArticleIntro>
          <ArticleSubTible>Qu’est-ce que le Saké ?</ArticleSubTible>
          <ArticleText>
            Le saké est un terme générique qui signifie en japonais « alcool ».
            En France, on utilise ce terme pour désigner le nihonshu,
            c’est-à-dire l’alcool de riz qui se situe entre 13 et 20° d’alcool.
            Le saké est souvent confondu avec le saké chinois, servi en fin de
            repas comme digestif, distillé et très fort. Il n’a pourtant rien à
            voir avec le saké japonais, qui quant à lui est très léger.
            <br></br>
            <br></br>
            Pour fabriquer du saké, les maitres brasseurs utilisent du sakamai,
            c’est-à-dire du riz non alimentaire, destiné au saké et donc
            contenant plus d’amidon. Une fois récolté, le riz est poli. Cette
            étape est décisive puisque c’est elle qui permettra de faire varier
            les arômes et les saveurs. Le riz poli est ensuite cuit, puis
            fermenté. On y ajoute enfin de l’eau de source des montagnes.
          </ArticleText>
          <ArticleSubTible>Quand et comment le déguster ? </ArticleSubTible>
          <ArticleText>
            Le saké japonais se déguste tout au long du repas, en apéritif ou en
            dessert. Il est appelé « vin de riz japonais » en raison de ses
            similitudes avec le vin français.
            <br></br>
            <br></br>
            Nous le servons chez Hara-kiri dans des petits verres, eux-même
            placés dans des masu (récipients cubiques en bois) pour faire
            déborder le saké. Cette tradition est censée représentée la
            générosité du serveur et marque une forme de respect.
            <br></br>
            <br></br>
            Dans la tradition japonaise, il est formellement interdit de se
            servir soi-même du saké. Il faut d’abord servir ses convives et
            attendre que ces derniers vous servent en retour.
          </ArticleText>
          <ArticleSubTible>Nos différentes sortes de Saké </ArticleSubTible>
          <ArticleText>
            Dès votre entrée chez Hara-Kiri, le mur de magnums de sakés éclairés
            vous annonce la couleur, nous sommes spécialisés en Saké.
            <br></br>
            <br></br>
            Nous proposons 11 références de saké directement importés du Japon
            et chaque référence apporte des subtilités propres à sa région de
            fabrication. Légers, fruités, pétillants, tous nos sakés sont de
            grande qualité et s’accordent parfaitement aux différentes saveurs
            de nos bouillons.
            <br></br>
            <br></br>
            Notre best seller est le saké Tatenokawa, qui vient de la région de
            Yamagate, au nord du Japon. Fabriqué à partir de riz Yamadanishiki,
            il libère des arômes fruités et floraux tout en restant très
            équilibré en bouche. Il se marie à la perfection avec notre ramen
            signature à la truffe noire.
            <br></br>
            <br></br>
            Si vous voulez en apprendre un peu plus sur les différents arômes et
            les différentes variétés, nous proposons une dégustation comparative
            de 3 sakés.
            <br></br>
            <br></br>
            Kampaï !
          </ArticleText>
        </ArticleTextContainer>
      </ArticleContainer>
    </ArticleSection>
  )
}

const ArticleSection = styled.section`
  margin-top: 100px;
  padding: 0 10%;
`

const ArticleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;

  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
  }
`

const ArticleTextContainer = styled.div`
  width: 80%;
  /* padding: 20px 60px; */
  margin: auto;

  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 20px;
  }
`

const ArticleTitle = styled.p`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 4px;
`
const ArticleDate = styled.p`
  margin-bottom: 40px;
  font-size: 14px;
`
const ArticleIntro = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
`

const ArticleSubTible = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`

const ArticleText = styled.p`
  font-size: 14px;
  margin-bottom: 40px;
`

const ArticleImageContainer = styled.div`
  width: 100%;
  height: 400px;
  background-color: #fff;
  margin-bottom: 40px;

  .articleImage {
    width: 100%;
    height: 50%;
    object-fit: cover;
    height: 400px;
  }

  @media screen and (max-width: 640px) {
    width: 80vw;
    height: 80vw;
  }
`

export default Blog
