import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Article4 from "../components/Article4"

const IndexPage = () => (
  <Layout headerColor={"black"}>
    <SEO title="Hara-Kiri, une référence en Saké" />
    <Article4 />
  </Layout>
)

export default IndexPage
